export const VIDEOS = [
  "/videos/LOCAL_FILM_Porch Pirate Official Trailer [70V_Z1Plfdw].mp4",
  "/videos/LOCAL_BAND_Blank Spell (Live in Philadelphia, PA @ The First Unitarian Church - 02.24.2024) [hJtkdjXw41k].mp4",
  "/videos/LOCAL_BAND_Skullpresser - Positions of Power (Official Video) [W3itMsjiXzs].webm",
  "/videos/LOCAL_FESTIVAL_PUFF9 Official Trailer (2024) [1TdslrZI64Y].mp4",
  "/videos/LOCAL_FILM_CADDY HACK  - OFFICIAL TRAILER [r5ekIlimxxw].mp4",
  "/videos/LOCAL_FILM_Coven of the Black Cube - 2024 Trailer - from Blood Sick Productions [8PvuhB5ii9g].mp4",
  "/videos/LOCAL_FILM_A Corpse For Christmas - Nice Trailer (blurred) - by Brewce Longo, starring Kasper Meltedhair [4LQETGyt5b4].mp4",
  "/videos/LOCAL_FILM_HeBGB TV OFFICIAL TRAILER [xcYsf_g8ju8].mp4",
  "/videos/LOCAL_FILM_PIZZA PARTY MASSACRE - Official Trailer [93QAvv8mzJ4].webm",
  "/videos/LOCAL_BAND_Zorn_Michief_Devils_Night_outdoor_local_10_30_2017_ewyG1lIOQOA.mp4",
  "/videos/LOCAL_BAND_Skullovich (St. Thrashricks Festival, Metro Gallery, Baltimore, Maryland, March 9th, 2024) [prRMf48IqO0].webm",
  "/videos/LOCAL_BAND_Timesup [McGe6Z5WFgg].webm",
  "/videos/LOCAL_BAND_Witching - Eschaton (Official Music Video) [20noD3q9mZg].webm",
  "/videos/LOCAL_BAND_NECROSEXUAL ＂DEAD SEXY＂ Music Video [OHV4Cz0NRuk].webm",
  "/videos/LOCAL_BAND_DEVIL MASTER - Acid Black Mass (Official Music Video) i-pqnibwdKw.webm",
  "/videos/LOCAL_BAND_Mannequin Pussy - ＂I Got Heaven＂ [EvgYIXc4JDA].webm",
  "/videos/LOCAL_BAND_SPITER ＂Tortured Soul＂ [3Ej32k_F5Hk].webm",
  "/videos/LOCAL_BAND_Doused - Hybrid Moments [fr7TBfCNccM].webm",
  "/videos/LOCAL_BAND_MORGUL BLADE - Heavy Metal Wraiths (OFFICIAL VIDEO) [MCnhn4kbst8].webm",
];
